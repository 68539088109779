import React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import { useAuthValue } from '../Auth/AuthContext'
import RoomNavigation from "./RoomNavigation"
import RoomHeader from "./RoomHeader"
import RoomContent from "./RoomContent"
import * as Styles from './TrainingRoom.module.scss'
import i18n from '../../i18n';
import { isAllowed, isTrainer } from "../../tools"

function createWelcomeLesson(trainingRoom, isTrainer) {
  const data = trainingRoom.data;
  var hasBookcode = data.Participants?.reduce((accumulator, currentValue ) => accumulator + currentValue.Bookcode?.length, 0) > 1?true:false;

  return {
    data: {
      Name: 'Home',
      Key: 'welcome'
    },
    rawBody: `
# Herzlich Willkommen zur Schulung ${data.Name}!
${data.Customer}\n
\n

${trainingRoom.rawBody}

${hasBookcode?`
## Freischaltung der E-Books
1. Legen Sie sich einmalig ein kostenloses Benutzerkonto auf der Website [www.rheinwerk-verlag.de](https://www.rheinwerk-verlag.de) an, falls Sie noch keines haben.
2. Nach der Anmeldung klicken Sie oben rechts neben dem Suchfeld auf **Bibliothek**.
3. Geben Sie im Bereich **Produkt- oder Abo-Schlüssel** den folgenden Zugangscode ein. Dann können Sie das Buch direkt online lesen oder das E-Book für Ihr Gerät herunterladen.
`:``}

${data.Participants ? `## Teilnehmer \n|Nr.|Vorname|Nachname|Email|${hasBookcode?`Bookcode|`:``}\n|---|---|---|---${hasBookcode?`|---`:``}|\n` + 
                       data.Participants.map(( participant, index) => 
                          `|${(index + 1).toString().padStart(2, '0')}|${participant.Firstname||'-'}|${participant.Lastname||'-'}|${participant.Email||'-'}${hasBookcode?'|'+participant.Bookcode:``}|'-'}|\n`
                          ).toString().replaceAll(",", '') : ''} 




${data.Trainers ? `## Trainer \n|Nr. |Vorname|Nachname|Email|\n|---|---|---|---|\n` + 
data.Trainers.map((trainer, index) => 
   `|${index + 1}|${trainer.Firstname||'-'}|${trainer.Lastname||'-'}|${trainer.Email||'-'}|\n`
   ).toString().replaceAll(",", '') : ''} 


`
  }
}

const TrainingRoom = ({ path, trainingRoom }) => {
  const data = trainingRoom.data;
  i18n.changeLanguage(data.Language ? data.Language : 'de');
  const { currentUser } = useAuthValue()
  const isTrainerFlag = isTrainer(data, currentUser);
  const [selectedLesson, setSelectedLesson] = useState('Null');
  const [selectedTopic, setSelectedTopic] = useState('Null');
  const [pageKey, setPageKey] = useState('Null');

  var lessons = [createWelcomeLesson(trainingRoom, isTrainerFlag)];

  trainingRoom.Lessons?.map(lesson => {
    lessons.push(lesson);
  })

  const newPageKey = data.Key + '-'
    + selectedLesson + '-'
    + selectedTopic;

  if (newPageKey !== pageKey) {
    setPageKey(newPageKey);
  }

  if (trainingRoom.Courses != null) {
    trainingRoom.Courses.forEach(course => {
      lessons = lessons.concat(course?.Lessons);

    })
  };
  lessons = lessons.filter(lesson => lesson ? true : false)


  if (!isAllowed(data, currentUser)) {
    return (
      <div > {currentUser ? (<h4>{i18n.t('noPrivileges')}</h4>)
        : (<div><h4> </h4>
          <Link to="/login">Login</Link></div>
        )}

      </div>);
  } else {

    return (
      <div className={Styles.trainingRoom}>
        <RoomHeader data={data} 
                    isTrainer={isTrainerFlag} />
        <div className={Styles.flexColumns}  >
          <RoomNavigation lessons={lessons}
            setSelectedLesson={setSelectedLesson}
            selectedLesson={selectedLesson}
            setSelectedTopic={setSelectedTopic}
            selectedTopic={selectedTopic}
            pageKey={pageKey}
            isTrainerFlag={isTrainerFlag} />

          <RoomContent lessons={lessons}
            lessonKey={selectedLesson}
            topicKey={selectedTopic}
            setSelectedTopic={setSelectedTopic}
            pageKey={pageKey} 
            isTrainerFlag={isTrainerFlag}/>
        </div>
      </div>
    )
  }
}


export default TrainingRoom
/*
      data {
        Key
        StartDate(formatString: "DD.MM.YYYY")
        EndDate(formatString: "DD.MM.YYYY")
        MeetingLink
        Customer
        Name
        Description {
          raw
        }
        Participants {
          data {
            Email
          }
        }
        Lessons {
          data {
            Name
            Description {
              raw
            }
            Duration_Live
            Duration_Video
            Key
            Topics {
              data {
                BookSides
                Key
                Name
                SlidesURL
                VideoID
                Description {
                  raw
                }
              }
            }
          }
        }
        TimeTable {
          raw
        }
        Courses {
          data {
            
        Lessons {
          data {
            Name
            Description {
              raw
            }
            Duration_Live
            Duration_Video
            Key
            Topics {
              data {
                BookSides
                Key
                Name
                SlidesURL
                VideoID
                Description {
                  raw
                }
              }
            }
          }
        }
          }
        }
      }
    }
  }
}*/