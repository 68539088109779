import React, { useState } from 'react'
import { db } from '../Firebase/Firebase'
import * as Styles from "./comments.module.scss"

import { IsTrainer, useAuthValue } from '../Auth/AuthContext'
import i18n from '../../i18n';
import {
  collection,
  doc,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore/lite'

const AddCommentSection = ({ pageKey, isTrainerFlag }) => {

  const [usrcomment, setUsrcomment] = useState('');
  const { currentUser } = useAuthValue()
  const { email } = currentUser

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  async function addComment(comment) {
    const newComment = doc(collection(db, 'blogComments'));
    setDoc(
      newComment,
      {
        slug: pageKey,
        comment,
        timestamp: serverTimestamp(),
        author: email
      },
      { merge: false },
    );
  }

  return (
    <IsTrainer isTrainerFlag={isTrainerFlag}>
      <div className={Styles.addComment}>
        <h3> {i18n.t('addComment')}</h3>
        <hr />
        <textarea
          onChange={(e) => setUsrcomment(e.currentTarget.value)}
        />
        <div>
          <button
            className={Styles.transparentButton}
            disabled={!usrcomment || usrcomment.trim() === ""}
            onClick={() => {
              addComment(usrcomment.trim()).then(() => {
                setUsrcomment("");
              });
              ;
            }}
          >
            {i18n.t('addComment')}
          </button>
        </div>
      </div>
    </IsTrainer>
  )
}


export default AddCommentSection
