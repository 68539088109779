import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useAuthValue } from './Auth/AuthContext'
import * as Styles from '../styles/certificate.module.scss'
import Markdown from "./Markdown"
import { isTrainer } from "../tools"

export default function Certificate({ trainingRoom }) {

  const data = useStaticQuery(graphql`
  query users {
    allAirtableUsers {
      nodes {
        data {
          Lastname
          Firstname
          Email
        }
      }
    }
  }
  `)
  const users = trainingRoom.data.Participants.concat(trainingRoom.data.Trainers);
  const { currentUser } = useAuthValue();
  const userArray = users.filter(user => { return user.Email.toLowerCase() === currentUser?.email.toLowerCase() });

  var oUser = userArray[0];

  const isTrainerFlag = isTrainer(trainingRoom.data, currentUser);

  return oUser?(( new Date().getTime() > new Date(trainingRoom.data.StartDate).getTime()||isTrainerFlag )
    ? (
      <div className={Styles.certFrame}>
        <div className={Styles.hideOnPrint}>
          Dieses Dokument kann gedruckt und dabei als PDF Dokument gespeichert werden. Dabei werden nicht relevante Stellen ausgeblendet.
        </div>
        <div className={Styles.certificate}> 
          <div className={Styles.certContent}>
            <img src="/Logo_mit_Text.svg" className={Styles.logo} ></img>

            <h2>Teilnahmebescheinigung</h2>
            <p className={Styles.highlighted}>{oUser.Firstname} {oUser.Lastname}  </p>

            <p> hat im Zeitraum vom {trainingRoom.data.StartDateDisplay} bis {trainingRoom.data.EndDateDisplay} aktiv und erfolgreich an der Schulung </p>
            <p className={Styles.highlighted}> {trainingRoom.data.Name}</p>
            <p>
              teilgenommen. Die Veranstaltung im zeitlichen Umfang von {trainingRoom.data.Duration} umfasste Inhalte zu den folgenden Themen:
            </p>
            <Markdown>
              {trainingRoom.data.CertificationTopics}
            </Markdown>

            <p>Mannheim, den {trainingRoom.data.EndDateDisplay}
              <br />
              <img src="/Unterschrift_gross.png" className={Styles.signature} />
              <br />
              Jörg Brandeis </p>

          </div>
        </div>
      </div>
    ) :
    (
      <div>
        <h1>Zertifikate werden erst nach der Veranstaltung erstellt!</h1>
      </div>
    )):( 
    (<div>
      <h1>Nicht angemeldet!</h1>
    </div>))
}



/*
      data {
        Key
        StartDate(formatString: "DD.MM.YYYY")
        EndDate(formatString: "DD.MM.YYYY")
        MeetingLink
        Customer
        Name
        Description {
          raw
        }
        Participants {
          data {
            Email
          }
        }
        Lessons {
          data {
            Name
            Description {
              raw
            }
            Duration_Live
            Duration_Video
            Key
            Topics {
              data {
                BookSides
                Key
                Name
                SlidesURL
                VideoID
                Description {
                  raw
                }
              }
            }
          }
        }
        TimeTable {
          raw
        }
        Courses {
          data {
            
        Lessons {
          data {
            Name
            Description {
              raw
            }
            Duration_Live
            Duration_Video
            Key
            Topics {
              data {
                BookSides
                Key
                Name
                SlidesURL
                VideoID
                Description {
                  raw
                }
              }
            }
          }
        }
          }
        }
      }
    }
  }
}*/