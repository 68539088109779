import React from "react";
import * as Styles from "./TrainingRoom/TrainingRoom.module.scss";
import { VideoChat } from "@carbon/icons-react";
import Section from "./TrainingRoom/Section";

const Vimeo = ({ VimeoID }) => {
  var url = `https://player.vimeo.com/video/${VimeoID}`;

  return (
    <Section className={Styles.iframeLimit} icon={<VideoChat />} title="Video" id="video">
      <div className={Styles.iframeLimit}>
      <div className={Styles.iframeContainer }>

      <iframe
        src={url}
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        title={VimeoID}
        className={Styles.iframeResponsive}
      />      </div></div>
      </Section>
  
  );
};
export default Vimeo;
