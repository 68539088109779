import * as React from 'react'
import PropTypes from 'prop-types';
import * as Styles from "./TrainingRoom.module.scss"


const Accordion = ({ title, content, lesson , setSelectedLesson, setSelectedTopic, selectedLesson}) => {

    const onClicked = () => {
        setSelectedLesson(lesson);
        setSelectedTopic(null);
    }

    return (
        <div className={`${Styles.accordion} ${selectedLesson===lesson ? Styles.uncollapsed : Styles.collapsed}`}>
            <button onClick={onClicked} >
                <span className={Styles.arrow}></span>
                {title}
            </button>
            <div className={Styles.accordionPanel}>
                {content}
            </div>
        </div>
    )
}

export default Accordion

Accordion.propTypes = {
    title: PropTypes.object,
    content: PropTypes.object
};