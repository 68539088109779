import * as React from 'react'
import * as Styles from "./TrainingRoom.module.scss"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { VideoChat, PresentationFile, StringText  } from '@carbon/icons-react'
const TopicTile = ({ data, icon, setSelectedTopic, number }) => {

  const onClicked = () => {
    setSelectedTopic(data.Key);
  }

  return (
    <a onClick={onClicked} >
      <div className={Styles.topicTile}>
        <h3>{data.Name}</h3>

        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {data.Excerpt}
        </ReactMarkdown>

      </div>
    </a>
  )
}
export default TopicTile 