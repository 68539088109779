import React from "react"
import { EventSchedule } from '@carbon/icons-react';
import { Certificate } from '@carbon/icons-react';
import { OrderDetails } from '@carbon/icons-react';
import * as Styles from './TrainingRoom.module.scss'
import { Link } from "gatsby";
import i18n from '../../i18n';

const RoomHeader = ({path, data, isTrainer}) => {
  const today = new Date().getTime();
  const enddate = new Date(data.EndDate).getTime();
  return (
    <div className={Styles.roomHeader}>
      <h1>{i18n.t("trainingRoom")} "{data.Name}"</h1>
      {data.StartDate?(<div><EventSchedule  /> {data.StartDateDisplay} - {data.EndDateDisplay} <br/></div>):''}
      {data.Customer?(<div><OrderDetails/> {data.Customer}</div>):''}
      {(enddate <= today||isTrainer)?(<Link to={`/app/cert/${data.Key.toLowerCase()}`}><Certificate/>Download Certificate</Link>):''}
      
    </div>
  )
}

export default RoomHeader
/*
      data {
        Key
        StartDate(formatString: "DD.MM.YYYY")
        EndDate(formatString: "DD.MM.YYYY")
        MeetingLink
        Customer
        Name
        Description {
          raw
        }
        Participants {
          data {
            Email
          }
        }
        Lessons {
          data {
            Name
            Description {
              raw
            }
            Duration_Live
            Duration_Video
            Key
            Topics {
              data {
                BookSides
                Key
                Name
                SlidesURL
                VideoID
                Description {
                  raw
                }
              }
            }
          }
        }
        TimeTable {
          raw
        }
        Courses {
          data {
            
        Lessons {
          data {
            Name
            Description {
              raw
            }
            Duration_Live
            Duration_Video
            Key
            Topics {
              data {
                BookSides
                Key
                Name
                SlidesURL
                VideoID
                Description {
                  raw
                }
              }
            }
          }
        }
          }
        }
      }
    }
  }
}*/