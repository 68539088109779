import { CommentType } from './CommentType'
import Comment from './Comment'
import React, { useState, useEffect } from 'react'
import { db } from '../Firebase/Firebase'
import i18n from '../../i18n';
import { Renew } from '@carbon/icons-react';
import * as Styles from './comments.module.scss'

import { collection, query, where, getDocs, orderBy, deleteDoc, doc } from 'firebase/firestore/lite'
var lastPageKey = '';
const CommentListSection = ({ pageKey, isTrainerFlag }) => {
  const [comments, setComments] = useState<CommentType[]>([])

  async function getComments(pageKey) {
    const commentsCollection = query(
      collection(db, 'blogComments'),
      where('slug', '==', pageKey ? pageKey : 'empty'),
      orderBy("timestamp", 'desc')
    )
    const commentSnapshot = await getDocs(commentsCollection)
    const commentList = commentSnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id }
    }) as CommentType[]
    return commentList
  }

  async function fetchComments(pageKey) {
    const commentList = await getComments(pageKey)
    setComments(commentList)
  }

  async function deleteComment(commentId){  
    return confirm('Are you sure?')
              ?deleteDoc(doc(db, "blogComments", commentId))
              :null;
  }

  
  useEffect(() => {
    fetchComments(pageKey)
  }, [])

  if (lastPageKey !== pageKey){
    lastPageKey = pageKey;
    fetchComments(pageKey);
  }

  return (
    <div>
      {comments.length > 0?(
        <div>
      <h3>{i18n.t('comments')}
      <button className={Styles.transparentButton} onClick={()=>{fetchComments(pageKey)}}><Renew /></button></h3>
      {comments.map((comment) => (
        <Comment comment={comment}
                 deleteComment={deleteComment} 
                 isTrainerFlag={isTrainerFlag}/>                 
      ))}
      </div>)
      :null
       }
    </div>
  )
}

export default CommentListSection
