import * as React from "react";
import * as Styles from './TrainingRoom.module.scss'

const Section = ({ title, children, icon , id}) => {

  return (    
      <div className={Styles.section} id={id}>   
           
       {title?(<h3>{title}  {icon}</h3>):null} 
        {children}
      </div>
  );
};
export default Section;