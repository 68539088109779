import React, { useState } from 'react'
import * as Styles from './comments.module.scss'
import i18n from '../../i18n'
import { IsTrainer, useAuthValue } from '../Auth/AuthContext'
import { TrashCan, Edit } from '@carbon/icons-react'
import Markdown from '../Markdown'
import { db } from '../Firebase/Firebase'
import { doc, setDoc, serverTimestamp } from 'firebase/firestore/lite'

const Comment = ({ comment, deleteComment, isTrainerFlag }) => {
  type User = {
    email: string
  }
  const [isEdit, setIsEdit] = useState(false)
  const [usrcomment, setUsrcomment] = useState(comment.comment)
  const { currentUser } = useAuthValue()
  const { email } = (currentUser as unknown) as User

  return (
    <div className={Styles.comment}>
      <div
        className={
          comment.author === email
            ? Styles.myCommentHeaderLine
            : Styles.commentHeaderLine
        }
      >
        {i18n.t('addedBy')} {comment.author} {i18n.t('wroteAt')}
        {new Date(comment.timestamp.seconds * 1000).toLocaleDateString('de-de')}
        {i18n.t('at')}{' '}
        {new Date(comment.timestamp.seconds * 1000).toLocaleTimeString('de-de')}{' '}
        {i18n.t('oclock')}
        <IsTrainer isTrainerFlag={isTrainerFlag}>
          <button
            onClick={async () => {
              await deleteComment(comment.id)
            }}
            className={Styles.transparentButton}
          >
            <TrashCan />
          </button>
          <button
            className={Styles.transparentButton}
            onClick={() => setIsEdit(!isEdit)}
          >
            <Edit />
          </button>
        </IsTrainer>
      </div>
      <hr />
      <div >

        <IsTrainer isTrainerFlag={isTrainerFlag}>{isEdit ? (
          <div className={Styles.update}>
            {comment.id}
            <textarea
              value={usrcomment}
              onChange={(e) => setUsrcomment(e.currentTarget.value)}
            />
            <button
              onClick={async () => {
                setDoc(
                  doc(db, 'blogComments', comment.id),
                  {
                    slug: comment.slug,
                    comment: usrcomment,
                    timestamp: serverTimestamp(),
                    author: comment.author,
                  },
                  { merge: false },
                )
              }}
              className={Styles.transparentButton}
            >
              {i18n.t('update')}
            </button>
          </div>
        ) : null}
        </IsTrainer>
        <Markdown>{comment.comment}</Markdown>
      </div>
    </div>
  )
}
export default Comment
