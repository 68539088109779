import PropTypes from 'prop-types';
import React from 'react';
import * as Styles from "./TrainingRoom.module.scss"

const AccordionLink = ({ title, topic , setSelectedTopic, selectedTopic}) => {

    const onClicked = () => {
        setSelectedTopic(topic);
    }

    return (
        <div className={`${Styles.accordionLink} ${selectedTopic===topic ? Styles.selected : Styles.unselected}`}>
            <a onClick={onClicked} >
                <span className={Styles.arrow}></span>
                {title}
            </a>
        </div>
    )
}

export default AccordionLink

AccordionLink.propTypes = {
    title: PropTypes.object,
    content: PropTypes.object
};