import * as React from "react";
import * as Styles from "./TrainingRoom.module.scss";
import Vimeo from "../Vimeo";
import Section from "./Section";
import i18n from "../../i18n";

import Markdown from "../Markdown";
import { VideoChat, PresentationFile, StringText, Download, GeneratePdf } from "@carbon/icons-react";
import Slides from "../Slides";

//import * as Styles from '../styles/blogLayout.module.scss'
const AnchorNavigation = ({ to, title }) => {
  return (
    <a href={`#${to}`} className={Styles.anchorLink}>
      {title}
    </a>
  );
};
const Topic = ({ topic }) => {
  return (
    <div>
      <h2>{topic.data.Name}</h2>
      <div className={Styles.flexRow}>
        {topic.data.VideoID ? (<Vimeo VimeoID={topic.data.VideoID} />) : null}

        {topic.data.SlidesURL ? (<Slides url={topic.data.SlidesURL} />) : null}
        <Markdown className={Styles.markdown}>{topic.rawBody.replace(/---.*?---/s, "")}</Markdown>
      </div>
    </div>
  );
};
export default Topic;