import * as React from 'react'

import { Task } from '@carbon/icons-react';
import { InformationSquare } from '@carbon/icons-react';
import i18n from '../../i18n';

import Topic from './Topic'
import TopicGrid from './TopicGrid'
import CommentListSection from '../Comments/CommentListSection';
import AddCommentSection from '../Comments/AddCommentSection';
import * as Styles from "./TrainingRoom.module.scss"

const Lesson = ({   lessonData,
                    topicKey,
                    setSelectedTopic,  //In case the tiles will be reused again...
                    pageKey,
                    isTrainerFlag }) => {
                        
    const allTopics = [].concat(lessonData.Topics).concat(lessonData.Exercises);
    const selectedTopic = allTopics.length > 0
        ? allTopics.filter(topic => {
            return topic
                ? topic.data.Key === topicKey
                : false
        })
        : [];

    const topic = selectedTopic.length > 0
        ? selectedTopic[0]
        : lessonData;

    return (
        <div className={Styles.lessonframe}>
            <Topic topic={topic} />
            { (<>
                <TopicGrid topics={lessonData.Topics}
                    title={i18n.t("topics")}
                    icon={(<InformationSquare className={Styles.tileIcon} size="24" />)}
                    setSelectedTopic={setSelectedTopic} />
                <TopicGrid topics={lessonData.Exercises}
                    title={i18n.t("exercises")}
                    icon={(<Task className={Styles.tileIcon} size="24" />)}
                    setSelectedTopic={setSelectedTopic} /></>
            ) }
            <CommentListSection pageKey={pageKey} 
                isTrainerFlag={isTrainerFlag}/>
            <AddCommentSection pageKey={pageKey}
                isTrainerFlag={isTrainerFlag} />


        </div>
    )
}
export default Lesson
