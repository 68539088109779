import React from "react";
import Accordion from "./Accordion";
import AccordionLink from "./AccordionLink";
import * as Styles from "./TrainingRoom.module.scss";
import i18n from "../../i18n";

const RoomNavigation = ({
  path,
  lessons,
  selectedLesson,
  setSelectedLesson,
  setSelectedTopic,
  selectedTopic,
  pageKey,
  isTrainerFlag
}) => {
    return (
    <div className={Styles.navigation}>
      <h2>{i18n.t("agenda")}</h2>
      {lessons
        ? lessons.map((lesson) => (
            <div>
              <Accordion
                title={lesson.data?.Name}
                setSelectedLesson={setSelectedLesson}
                setSelectedTopic={setSelectedTopic}
                lesson={lesson.data?.Key}
                selectedLesson={selectedLesson}
                content={
                  <div>
                    {lesson.Topics ? (
                      <div>
                        <div className={Styles.accordionGroup}>
                          {i18n.t("topics")}
                        </div>
                        {lesson.Topics.filter((topic) =>
                          topic ? true : false
                        ).map((topic) => (
                          <AccordionLink
                            title={topic.data.Name}
                            topic={topic.data.Key}
                            setSelectedTopic={setSelectedTopic}
                            selectedTopic={selectedTopic}
                          />
                        ))}
                      </div>
                    ) : null}
                    {lesson.Exercises ? (
                      <div>
                        <div className={Styles.accordionGroup}>
                          {i18n.t("exercises")}
                        </div>
                        {lesson?.Exercises.map((topic) => (
                          <AccordionLink
                            title={topic?.data.Name}
                            topic={topic?.data.Key}
                            setSelectedTopic={setSelectedTopic}
                            selectedTopic={selectedTopic}
                          />
                        ))}
                      </div>
                    ) : null}
                  </div>
                }
              />
            </div>
          ))
        : ""}
        {isTrainerFlag?(
                    <p style={{ color: `lightgrey` }}>
                      Navigation Info
                      <li>Lesson: {selectedLesson}</li>
                      <li>Topic: {selectedTopic}</li>
                    </p>):null}
    </div>
  );
};

export default RoomNavigation;
