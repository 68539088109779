import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import * as Styles from "./markdown.module.scss";
import abap from "../highlight.js/lib/languages/abap";
import cds from "../highlight.js/lib/languages/cds";
import bdl from "../highlight.js/lib/languages/bdl"
import rehypeRaw from "rehype-raw";
import Vimeo from "./Vimeo";
import Slides from "./Slides";

function VimeoComp({ id, ...props }) {
  return (
    <Vimeo VimeoID={id}/>
  );
}

function SlidesComp({ url, ...props }) {
  return (
    <Slides url={url}/>
  );
}

const Markdown = ({ children }) => {
  return (
    <div className={Styles.markdown}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[
          [rehypeHighlight, { languages: { abap: abap, 
            cds: cds, 
            bdl: bdl } }],
          rehypeRaw,
        ]}
        components={{ vimeo: VimeoComp,
                      slides: SlidesComp }}
      >
        {children}
      </ReactMarkdown>
    </div>
  );
};

export default Markdown;
