import React from "react"
import { Router, Redirect } from "@reach/router"
import Layout from "../../components/Layout"
import TrainingRoom from "../../components/TrainingRoom"
import Certificate from "../../components/Certificate"
import { graphql } from "gatsby"

export default function App({ data }){ 
  data.allMdx.nodes.forEach(trainingRoom => {
    trainingRoom.data.StartDateDisplay = new Date(trainingRoom.data.StartDate).toLocaleDateString();
    trainingRoom.data.EndDateDisplay = new Date(trainingRoom.data.EndDate).toLocaleDateString();
  });
  return  (  
  <Layout>
    <Router>
      {data.allMdx.nodes.map(node => (
        <TrainingRoom path={'/app/room/' + node.data.Key.toLowerCase()}
                      trainingRoom={node} />
      ) )}
      {data.allMdx.nodes.map(node => (
        <Certificate path={'/app/cert/' + node.data.Key.toLowerCase()}
                      trainingRoom={node} />
      ) )}
    </Router>
  </Layout>
)}

export const query = graphql`
query trainingRooms {
allMdx(filter: {frontmatter: {type: {eq: "trainingRoom"}}}) {
  nodes {
    rawBody
    data:frontmatter {
      Key
      StartDate
      EndDate
      MeetingLink
      Customer
      Name
      Duration
      CertificationTopics
      Courses
      Participants{
        Email
        Firstname
        Lastname
        Bookcode
      }
      Trainers{
        Email
        Firstname
        Lastname
      }
      Names{
        Email
        Firstname
        Lastname
      }
    }
    Courses {
      body
      rawBody
      data:frontmatter {
        Name
        Key
        type
      }
      Lessons{
        ...LessonAttributes
      }
    }
    Lessons {
      ...LessonAttributes
    }
  }
}
}

fragment LessonAttributes on Mdx {
  body
  rawBody
  mdxAST
data:frontmatter {
  Name
  SlidesURL
  VideoID
  Key
}
Topics {
...TopicAttributes
}
Exercises {
...TopicAttributes
}
}

fragment TopicAttributes on Mdx {
  body
  rawBody
  mdxAST
data:frontmatter {
  Key
  Name
  SlidesURL
  VideoID
  Excerpt
}
}
`