import * as React from "react"
import TopicTile from "./TopicTile"
import * as Styles from "./TrainingRoom.module.scss"
import Section from "./Section"
// markup
const TopicGrid = ({ topics, title, icon, setSelectedTopic }) => {

    return topics ? (
        <Section title={title}>
        <div className={Styles.topicGrid}>
            {
                topics.filter(t => (t != null)).map((topic, index) => (
                    <TopicTile data={topic.data}
                        number={index}
                        icon={icon}
                        setSelectedTopic={setSelectedTopic} />
                ))
            }
        </div>
        </Section>
    ) : null
}

export default TopicGrid