import React from "react";
import * as Styles from "./TrainingRoom/TrainingRoom.module.scss";
import i18n from "../i18n";
import { VideoChat, PresentationFile, StringText, Download, GeneratePdf } from "@carbon/icons-react";
import Section from "./TrainingRoom/Section";
const Slides = ({ url }) => {
  if (url.substr(0,4).toUpperCase() != 'HTTP'){
    url = 'https://captainabap.github.io/slides/docs' + url;
    if (!url.endsWith('.html')){
      url += '.html';
    }
  }
  return (
    <Section
      title={i18n.t("slides")}
      icon={<span><a href={url.replace('.html', '.pdf').replace('/docs/', '/pdf/')} download target="_blank"><GeneratePdf /></a>
        <a href={url} download target="_blank"><Download /></a></span>}
      
    >
      <div className={Styles.iframeLimit}>
        <div className={Styles.iframeContainer}>
          <iframe
            src={url}
            frameborder="0"
            allow="autoplay; fullscreen"
            className={Styles.iframeResponsive}
            allowfullscreen
          />
        </div>
      </div>
    </Section>
  );
};
export default Slides;
