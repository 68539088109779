import React from "react"
import Lesson from "./Lesson"
import * as Styles from './TrainingRoom.module.scss'

const RoomContent = ({ path, 
                       lessons , 
                       lessonKey, 
                       topicKey, 
                       setSelectedTopic,
                      pageKey,
                      isTrainerFlag}) => {
  const singleLesson = lessons.filter( lesson => { return lesson.data.Key === lessonKey; });
  const lessonData = singleLesson.length>0?singleLesson[0]:lessons[0];
                  
    return (
      <div className={Styles.content}>
        {lessonData?(<Lesson lessonData={lessonData} 
                topicKey={topicKey}
                pageKey={pageKey}
                setSelectedTopic={setSelectedTopic}
                isTrainerFlag={isTrainerFlag}/>):null}
      </div>
    )
}

export default RoomContent